import './styles/style.scss';
import {gsap} from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import header from './blocks/header_block';
// import hero from './blocks/hero_block';
import {initBlocks} from './blocks';
import {getHeightOfViewPort} from './scripts/functions/getHeightOfViewPort';
import {generateDataHover} from './scripts/functions/generateDataHover';
import {scrollToHash} from './scripts/general/scroll-to-hash';
import {fixContainer} from './scripts/general/fix-container';
import {initModal} from "./scripts/general/custom-modal";
import {stickySidebar} from "./scripts/general/sticky-sidebar";
import {activePage} from "./scripts/general/active-page";
import {breakLine} from "./scripts/functions/breakLine";


const reInvokableFunction = async (container = document) => {
  container.querySelector('header') && await header(container.querySelector('header'));
  // container.querySelector('.hero_block') && await hero(container.querySelector('.hero_block'));

  await initBlocks(container);

  generateDataHover(container);
  scrollToHash(container);
  // fixContainer(container);
  stickySidebar(container);
  getHeightOfViewPort(container);
  activePage(container);
  breakLine(container);

  ScrollTrigger.refresh(false);
};
let loaded = false;

async function onLoad() {
  gsap.config({
    nullTargetWarn: false,
  });
  if (document.readyState === 'complete' && !loaded) {
    loaded = true;
    initModal();
    gsap.registerPlugin(ScrollTrigger);

    await reInvokableFunction();
    document.body.classList.add('loaded');
  }
}

onLoad();

document.onreadystatechange = function () {
  onLoad();
};
