import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {getElementsForAnimation} from '../../functions/getElementsForAnimation';

gsap.registerPlugin(ScrollTrigger);


export function iconAnimation(container = document) {
  const svgIcons = getElementsForAnimation(container, '.pinnacle-icon');
  ScrollTrigger.batch(svgIcons, {
    onEnter: batch => {
      for (let i = 0; i < batch.length; i++) {
        const icon = batch[i];

        const drawSvg = icon.querySelectorAll("svg .draw-svg")
        const scaleIcon = icon.querySelectorAll("svg .scale-svg")
        const iconBackground = icon.querySelectorAll("svg .background")
        gsap.timeline({delay: i * 0.25, repeat: -1, repeatDelay:.5})
          .set(icon, {opacity: 1})
          .add(gsap.timeline({repeat: 1, repeatDelay: 3, yoyo: true})
            .from(drawSvg, {
              drawSVG: 0,
              duration: 1,
            })
            .from(scaleIcon, {
              opacity: 0,
              scale: 1.1,
              transformOrigin: "center"
            }, "<80%")
            .from(iconBackground, {
              // scale:0,
              opacity: 0,
              ease: 'linear',
              transformOrigin: "center",
              duration: 1
            }, 0)
          )
      }
    },
    batchMax: 4,
    interval: 0.3,
    start: 'top 90%',
    once: true,
  });

  //logo animation
  const pinnacleLogo = document.querySelectorAll(".pinnacle-logo")
  const logoShapes = document.querySelectorAll(".pinnacle-logo path")
  const duration = 5
  for (let i = 0; i < pinnacleLogo.length; i++) {

    gsap.timeline({repeat: -1})
      .fromTo(logoShapes, {
        fill: "#0169b1",
      }, {
        fill: "#00a8e4",
        stagger: duration / 8,
        duration: duration / 8,
      })
      .to(logoShapes, {
        fill: "#0169b1",
        stagger: duration / 8,
        duration: duration / 8,
      })
  }

}
